<template>
  <div class="col-wrapper box">
    <div class="contain col-wrapper">
      <div class="title col-wrapper">
        <!--        <div class="title">建享教育</div>-->
        <div class="subTitle">乡土文化研学平台</div>
      </div>
      <div class="col-wrapper form">
        <div class="input-contain row-wrapper">
          <div class="icon-contain col-wrapper">
            <User color="#7D7E81"
                  class="icon"/>
          </div>
          <input v-model="form.UserName"
                 placeholder="请输入账号"
                 class="input"/>
        </div>
        <div class="input-contain row-wrapper">
          <div class="icon-contain col-wrapper">
            <Lock color="#7D7E81"
                  class="icon"/>
          </div>
          <input type="password"
                 placeholder="密码"
                 v-model="form.PassWord"
                 class="input"/>
        </div>
        <div class="row-wrapper forget-password">
<!--          <span @click="$router.push({path:'/forget'})"-->
<!--                class="forget-password">忘记密码?</span>-->
        </div>
        <Button @click="login"
                :type="'primary'"
                class="login">登录
        </Button>
      </div>
      <div class="register row-wrapper">
<!--        <span class="register"-->
<!--              @click="$router.push({path:'/register'})">注册账号</span>-->
      </div>
    </div>
  </div>
</template>

<script>
import * as AccountApi from "../../api/api/Account"

export default {
  name: "Login",
  data() {
    return {
      form: {
        UserName: undefined,
        PassWord: undefined
      }
    }
  },
  methods: {
    /**
     * 登录 设置token
     */
    login() {
      AccountApi.Login(this.$qs.stringify(this.form))
          .then(res => {
            if (res.data.code === 200) {
              this.$store.commit("setToken", res.data.data.token)
              this.$store.commit("setName", res.data.data.userName)
              this.$router.push({path: '/publish'})
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("登陆失败")
          })
    }
  }
}
</script>

<style scoped lang="less">
.box {
  height: 100vh;

  ::v-deep(.input-contain) {
    height: 45px;
  }

  .contain {
    margin-top: 110px;
    width: 375px;
    align-items: stretch;

    .title {
      padding: 20px;

      .title {
        font-weight: 300;
        font-size: 30px;
        color: #000;
      }

      .subTitle {
        font-weight: 400;
        font-size: 2em;
        color: #666
      }

    }

    .form {
      padding: 20px;
      align-items: stretch;
      align-self: stretch;

      .login {
        margin: 0;
        align-self: stretch;
      }

      .input-contain {
        align-self: stretch;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        margin: 5px 0;

        .input {
          outline: none;
          padding-left: 0;
          border: 1px solid transparent;
          align-self: stretch;
        }
      }

      .forget-password {
        justify-content: flex-end;
        padding: 20px 0;

        .forget-password {
          font-size: 14px;
          padding: 0;
          color: #409EFF
        }

        .forget-password:hover {
          cursor: pointer;
        }
      }
    }

    .register {
      justify-content: flex-end;
      padding: 0 20px;

      .register {
        font-size: 14px;
        padding: 0;
        color: #409EFF
      }

      .register:hover {
        cursor: pointer;
      }
    }
  }

}

.icon-contain {
  padding: 5px 10px;

  .icon {
    width: 20px;
    height: 20px;
  }
}
</style>